import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AppStateService {
    private localStorageService: LocalStorageService = inject(LocalStorageService);
    private _isSidebarExpanded: boolean;

    constructor() {
        const isSidebarNavExpandedItem = LocalStorageService.getItem('isSidebarNavOpen');

        if (isSidebarNavExpandedItem) {
            this._isSidebarExpanded = isSidebarNavExpandedItem == 'true' || false;
        }
        else {
            this._isSidebarExpanded = true;
            LocalStorageService.setItem('isSidebarNavOpen', this._isSidebarExpanded);
        }
    }

    public get isSidebarExpanded(): boolean {
        return this._isSidebarExpanded;
    }

    public set isSidebarExpanded(value: boolean) {
        this._isSidebarExpanded = value;
        LocalStorageService.setItem('isSidebarNavOpen', value);
    }
}
