import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { AppStateService } from '../../../core/services/app-state.service';

@Component({
    selector: 'app-goal-display',
    templateUrl: './goal-display.component.html',
    styleUrls: ['./goal-display.component.scss']
})
export class GoalDisplayComponent {
    private appStateService: AppStateService = inject(AppStateService);

    public get isSidebarExpanded(): boolean {
        return this.appStateService.isSidebarExpanded;
    }

    constructor() { }

    @Output()
    public gotoGoalDetailsRequested: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    public goal: any;

    @Input()
    public isNavigable: boolean = true;

    public goalDetailsEnterKeyPress(goal: any, event: KeyboardEvent) {
        if (event.code === 'Enter') {
            this.gotoGoalDetailsRequested.emit(goal.theme);
        }
    }

    public goalDetailsClicked() {
        this.gotoGoalDetailsRequested.emit(this.goal.theme);
    }
}
