import { Component, OnDestroy, HostListener, Input, NgZone, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { GoalColors } from '../../../shared/goal-colors';
import { environment } from '../../../../../environments/environment';
import { debounceTime, distinctUntilChanged, fromEvent, Subscription } from 'rxjs';

am4core.useTheme(am4themes_animated);
am4core.addLicense(environment.amCharts.license);
am4core.options.autoSetClassName = true;

@Component({
    selector: 'app-goal-donut-chart',
    templateUrl: './goal-donut-chart.component.html',
    styleUrls: ['./goal-donut-chart.component.scss']
})
export class GoalDonutChartComponent implements OnInit, OnDestroy {

    constructor(
        private zone: NgZone
    ) { }

    public resizeSubscription: Subscription;

    public ngOnInit(): void {
        this.resizeSubscription = fromEvent(window, 'resize')
            .pipe(
                debounceTime(200),
                distinctUntilChanged()
            )

            .subscribe((event: any) => {
                if (this.chart) {
                    this.chart.dispose();
                    this.renderChart();
                }
            });
    }

    private chart: am4charts.PieChart;

    private _goal: any

    public get goal(): any {
        return this._goal;
    }

    @Input()
    public set goal(value: any) {
        this._goal = value;
        this.renderChart();
    }

    @Input()
    public goalIndex: number;

    @Input()
    public subThemeIndex: number;

    private renderChart(): void {
        setTimeout(() => {
            this.zone.runOutsideAngular(() => {
                const subTheme = this.goal.subThemeValues[this.subThemeIndex];
                const percentComplete = Number(subTheme.totalProgressValue) / Number(subTheme.organizationalTargetValue);
                const strPercentComplete = Number(percentComplete).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 1 });

                // Handle situation where the progress is greater than the target.
                const targetRemaining = percentComplete > 1 ? 0 : 1 - percentComplete;

                let chart = am4core.create('chartdiv_' + this.goalIndex + '_' + this.subThemeIndex, am4charts.PieChart);
                let goalColorName = 'goal_' + this.goal.goal2030Id;
                let goalColor = GoalColors[goalColorName];

                chart.data = [
                    {
                        series: 'progress',
                        progress: percentComplete,
                        color: am4core.color(goalColor)
                    },
                    {
                        series: 'total',
                        progress: targetRemaining,
                        color: am4core.color('#ddd')
                    }
                ];

                chart.innerRadius = am4core.percent(65);

                const label = chart.seriesContainer.createChild(am4core.Label);
                label.text = strPercentComplete;
                label.horizontalCenter = 'middle';
                label.verticalCenter = 'middle';

                label.fontSize = 40;

                if (window.innerWidth <= 1440) {
                    label.fontSize = 36;
                }
                if (window.innerWidth <= 1280) {
                    label.fontSize = 32;
                }
                if (window.innerWidth <= 1024) {
                    label.fontSize = 26;
                }

                label.fontWeight = 'bold';
                label.fill = am4core.color(goalColor);

                const pieSeries = chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = 'progress';
                pieSeries.dataFields.category = 'series';
                pieSeries.labels.template.disabled = true;
                pieSeries.ticks.template.disabled = true;
                pieSeries.slices.template.tooltipText = '';
                pieSeries.slices.template.propertyFields.fill = 'color';
                pieSeries.slices.template.focusable = false;
                pieSeries.slices.template.tabindex = -1;

                // disable default hover effect
                var hoverState = pieSeries.slices.template.states.getKey("hover");
                hoverState.properties.scale = 1;

                // chart.responsive.rules.push({
                //     relevant: function(target) {

                //       // Code that determines if rule is currently relevant
                //       // and returns true or false
                //       return true;
                //     },
                //     state: function(target, stateId) {
                //         if (target instanceof am4core.Label) {
                //             const state = target.states.create(stateId);
                //             state.properties.fontSize = 25;
                //             return state;
                //         }
                //       // Code that creates a new SpriteState object to be
                //       // applied to target if Rule is "relevant"
                //       return null;
                //     }
                //   });

                this.chart = chart;
            });
        });
    }

    public ngOnDestroy(): void {
        if (this.chart) {
            this.chart.dispose();
        }
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }
}
