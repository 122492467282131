<div class="add-edit-progress-dialog-container" *ngIf="editProgressForm else loading" [formGroup]="editProgressForm">

    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">{{!existingProgress ? 'Add': 'Edit'}} Outcome {{progressType | titlecase}}</h5>
    </div>

    <div class="row">
        <div class="col-md-12">
            <p class="text-center font-weight-bold sb-b p-1">
                Set a {{progressType | titlecase}} Value for your Outcome Metric
            </p>
        </div>
    </div>

    <div class="content hub-custom-scrollbar">

        <!--Original UI for non-NCS Outcomes -->
        <div class="progress-panel" *ngIf="!isNcsOutcome else ncsProgressPanel" >

            <div class="progress-date-column">
                <span class="select-label text-info-800">
                    {{ progressType | titlecase }} Date
                    <app-help-selector [selector]="isStart ? 1074 : 1084"></app-help-selector>
                </span>

                <mat-form-field>
                    <input matInput readonly (click)="progressDatePicker.open()" [matDatepicker]="progressDatePicker" formControlName="progressDate" placeholder="{{progressType}} Date">
                    <mat-datepicker-toggle matIconSuffix [for]="progressDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #progressDatePicker></mat-datepicker>
                </mat-form-field>

                <div class="hub-validation-error-message" [innerHtml]="getValidationErrorMessages('progressDate')"></div>

            </div>

            <div class="progress-value-column">

                <span class="select-label text-info-800">
                    {{progressType | titlecase}} Value
                    <app-help-selector [selector]="isStart ? 1076 : 1086"></app-help-selector>
                </span>

                <mat-form-field>
                    <input matInput  class="form-control" formControlName="progressValue" placeholder="{{progressType}} Value"
                    type="number" />
                </mat-form-field>

                <div class="hub-validation-error-message" [innerHtml]="getValidationErrorMessages('progressValue')"></div>

            </div>
            <div class="units-column">

                <span class="select-label text-info-800 d-block">
                    Units<app-help-selector [selector]="1078">
                    </app-help-selector>
                </span>
                <div class="form-control" disabled="disabled" style="padding-top: 13px; padding-bottom: 18px">
                    {{ getUnitName() }}
                </div>

            </div>
        </div>

        <ng-template #ncsProgressPanel>

            <div>
                <div class="ncs-info-panel">
                    <div class="pathway">
                        <div class="select-label">NCS Pathway</div>
                        <div class="value-label">{{projectOutcome.outcome.ncsIntervention.pathwayName}}</div>
                    </div>
                    <div class="intervention">
                        <div class="select-label">Intervention Type</div>
                        <div class="value-label">{{projectOutcome.outcome.ncsIntervention.name}}</div>
                    </div>
                    <div class="country">
                        <div class="select-label">Country</div>
                        <div class="value-label">{{projectOutcome.outcome.ncsCountry.name}}</div>
                    </div>
                </div>

                <div class="ncs-panel">
                    <div class="spatial-extent-panel">
                        <label class="select-label">Spatial Extent</label>

                        <mat-form-field>
                            <input matInput type="number" [formControl]="ncsExtentControl">
                        </mat-form-field>

                        <label class="unit-label">hectares</label>
                    </div>
                    <div class="spatial-extent-panel">
                        <div></div>

                        <small class="hub-validation-error-message">
                            {{ getExtentValidationErrorMessage() }}
                        </small>

                    </div>

                    <app-ncs-estimates-panel
                        [ncsInterventionId]="projectOutcome.outcome.ncsIntervention.interventionId"
                        [ncsCountryId]="projectOutcome.outcome.ncsCountry.geographyId"
                        [extent]="ncsExtentControl.value"
                        [metricType]="progressType"
                        (metricValueChanged)="progressValueChanged($event)"
                        (pendingRecalculationChanged)="pendingNcsRecalculationChanged($event)"
                        >
                    </app-ncs-estimates-panel>

                    <div class="progress-data-panel">
                        <h5>{{progressType}} Data</h5>

                        <div class="progress-data-row">
                            <label>{{progressType | titlecase}} Date</label>
                            <div>
                                <mat-form-field>
                                    <input matInput readonly (click)="progressDatePicker.open()" [matDatepicker]="progressDatePicker" formControlName="progressDate" placeholder="{{progressType}} Date">
                                    <mat-datepicker-toggle matIconSuffix [for]="progressDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #progressDatePicker></mat-datepicker>
                                </mat-form-field>

                                <div class="hub-validation-error-message" [innerHtml]="getValidationErrorMessages('progressDate')"></div>

                            </div>
                        </div>

                        <div class="progress-data-row">
                            <label>{{progressType | titlecase}} Value</label>

                            <div class="progress-value-panel" [ngClass]="{'invalid': isProgressValueInvalid()}">
                                <span class="progress-value">{{progressValue | number : '1.3-3'}}</span>
                                <span class="progress-unit">tCO2e/yr</span>
                            </div>

                            <div>
                                <a href="Javascript: void(0)" (click)="showCalculations()">Show Calculations</a>
                            </div>
                        </div>

                        <div class="progress-data-row">
                            <small class="hub-validation-error-message">
                                {{ getValidationErrorMessages('progressValue') }}
                            </small>
                        </div>

                    </div>

                </div>
            </div>

        </ng-template>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <span class="select-label text-info-800 d-block mt-2">
                        {{progressType | titlecase}} Description<app-help-selector
                            [selector]="isStart ? 1082 : 1183"></app-help-selector>
                    </span>
                    <textarea rows="3" cols="5" class="form-control record-description" formControlName="attributionDescription"
                        placeholder="Enter your {{progressType}} description here"></textarea>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <span class="select-label text-info-800 d-block mt-2">
                        TNC's Role<app-help-selector
                            [selector]="1080">
                        </app-help-selector>
                    </span>

                    <div class="hub-select-container">
                        <mat-form-field>
                            <mat-select placeholder="Select TNC's Role" disableOptionCentering
                                formControlName="attributionLevelId">
                                <mat-option *ngFor="let aLevel of attributionLevels"
                                    [value]="aLevel.attributionLevelId">
                                    {{aLevel.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="hub-validation-error-message">
                        {{getValidationErrorMessages('attributionLevelId')}}
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="hub-modal-footer-text">
        <button type="button" class="cancel-button mr-1" (click)="cancel()">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="isSaveDisabled() || isSaving" (click)="save()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>
    </div>
</div>

<ng-template #loading>
    <div style="position: relative; height: 100%; width: 100%;">
        <app-modal-spinner></app-modal-spinner>
    </div>
</ng-template>
