import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formatDistance } from 'date-fns';
import { UserDataService } from '../../../../core/services/user-data.service';
import { ProjectStatuses } from '../../../types/project-statuses';
import { ProjectCardService } from './../services/project-card.service';
import { take } from 'rxjs/operators';
import { ToastService } from '../../../../core/services/toast.service';
import { Router } from '@angular/router';
import { BusinessUnitModel, ProjectModel, UserModel } from '../../../../../hub_schema/hubTypes';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { RecordCollectionService } from '../../../../core/services/record-collection.service';

const LEAD_ID: number = 1000;

@Component({
    selector: 'app-project-card-graphql',
    styleUrls: ['./project-card-graphql.component.scss'],
    templateUrl: './project-card-graphql.component.html',
})
export class ProjectCardGraphQlComponent implements OnInit {

    constructor(
        public userDataService: UserDataService,
        private projectCardService: ProjectCardService,
        public projectCollectionService: RecordCollectionService,
        private toastService: ToastService,
        private router: Router,
        private dialogService: MatDialog
    ) {
        this.currentFiscalYear = this.projectCardService.currentFiscalYear;
    }

    private _project: ProjectModel;

    public get project() {
        return this._project;
    }

    @Input()
    public goals: any[];

    @Input()
    public set project(value: ProjectModel) {
        this._project = value;

        if (!value.projectBusinessUnits) {
            return;
        }

        const leadProjectBu = value.projectBusinessUnits.find((pbu) => pbu.isLeadBusinessUnit);

        if (leadProjectBu) {
            this.leadBusinessUnitId = leadProjectBu.businessUnit!.businessUnitId;
        }
    }

    @Input()
    public recordCollectionProjectId: number | undefined;

    public get hubRecordId(): number {
        return this.project.projectId;
    }

    public get recordTypeName(): string {
        return this.project.projectType!.name;
    }

    public get statusName(): string {
        return this.project.status!.name;    }

    public get dateModified(): Date | undefined {
        return this.project.modifiedOn;
    }

    @Input() showCardDetails: boolean;
    @Input() showFinanceDetails: boolean;
    @Input() userIsCreator = false;
    @Input() isUserCanEdit = false;
    @Input() addToCollection = true;

    @Output()
    public recordCollectionProjectDeleted: EventEmitter<number> = new EventEmitter();

    @Output()
    public switchTabToFavoritesRequested: EventEmitter<void> = new EventEmitter<void>();

    currentFiscalYear: number;
    progressBarStyle: { width: string; };
    public leadBusinessUnitId: number;

    ngOnInit() {
        this.progressBarStyle = {
            width: 33 + '%',
        };
    }

    public getDistanceInWordsToNow(): string {
        const date = new Date(this.dateModified!);
        const today = new Date();
        return formatDistance(date, today, { addSuffix: true });
    }

    public getProjectLeader(): UserModel | undefined {
        const projectLead = this.project.team!.find(t => t.userBusinessRoles![0].businessRoleId === LEAD_ID);
        return projectLead?.user;
    }

    public getLeadBusinessUnit(): BusinessUnitModel | undefined {
        const leadProjectBusinessUnit = this.project.projectBusinessUnits?.find(pbu => pbu.isLeadBusinessUnit);
        return leadProjectBusinessUnit?.businessUnit;
    }

    public getFinancial(financialsType: string): number | undefined {
        if (this.project.projectFinancials!.length > 0) {
            const financial = this.project.projectFinancials!.find(pf => pf.financeMetric!.code === financialsType);

            if (financial) {
                return financial.value;
            }
        }
        return undefined;
    }

    // TODO: This method is in the code base at 3 different locations. Consolidate them! (ecoffman - 7/13/21)
    public numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    }

    public getClassFromStatus(status: ProjectStatuses): string {
        switch (status) {
            case ProjectStatuses.Active:
                return 'text-success';
            case ProjectStatuses.Approved:
                return 'text-indigo';
            case ProjectStatuses.Completed:
                return 'text-grey-600';
            case ProjectStatuses.Canceled:
                return 'text-danger';
            case ProjectStatuses.Proposed:
                return 'text-primary';
        }
    }

    deleteRecordCollectionProjectClicked(): void {
        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'Do you want to delete this record from this collection?'
            },
            disableClose: true
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe((res) =>
        {
            this.removeProjectFromCollection()
            dialogRef.close();
        });
    }

    private removeProjectFromCollection(): void {
        this.projectCollectionService.deleteProjectFromCollection(this.recordCollectionProjectId!).subscribe({
            next: () => {
                this.recordCollectionProjectDeleted.emit(this.recordCollectionProjectId);
            },
            error: (err) => {
                console.log(err);
            }
        });
    }

    public toggleFavorite(projectId: number, e: Event): void {
        const isFavorite = this.userDataService.isFavorite(projectId);
        this.userDataService.toggleFavorite(projectId);

        if (!isFavorite) {
            this.toastService.show('Saved to Favorites', 'Go to Favorites');

            this.toastService.gotoRequested.pipe(take(1)).subscribe(() => {
                this.toastService.clear();
                // const stateObj = { state: {} };
                // stateObj.state['tab'] = 'favorites';
                // this.router.navigateByUrl('/user', stateObj);
                this.switchTabToFavoritesRequested.emit();
            });
        }

        e.preventDefault();
        e.stopPropagation();
    }

    public getProjectGoals(): any[] {

        if (!this.goals) {
            return [];
        }

        let projectThemes: string[];
        const tncOutcomes = this.project.projectOutcomes!.filter(o => o.outcome !== null);
        projectThemes = tncOutcomes.map(o => o.outcome!.outcomeMetric!.focalThemeSubArea);

        if (!projectThemes) {
            return [];
        }

        const result = this.goals.filter(g => projectThemes.includes(g.theme));
        return result;
    }
}
