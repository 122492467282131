<div class="create-intermediate-result-metric-container" *ngIf="progressForm">

    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">{{!existingProgress ? 'Add' : 'Edit'}} Intermediate Result {{isStart ? 'Start': 'Progress'}}</h5>
    </div>

    <div class="content">
        <div [formGroup]="progressForm">
            <div class="row">
                <div class="col-md-12">

                    <p class="text-center font-weight-bold sb-b p-1">
                        Set a {{isStart ? 'Start': 'Progress'}} Value for your Intermediate Result
                    </p>
                    <h5 class="text-success text-center p-1">{{intermediateResult.indicatorName}}
                        ({{intermediateResult.unit}})</h5>
                </div>
            </div>

            <div class="row">

                <div class="col-md-12">
                    <div class="progress-container">

                        <div class="progress-date-column">
                            <div>
                                <span class="select-label text-info-800">
                                    {{ isStart ? 'Start' : 'Progress' }} Date
                                    <app-help-selector [selector]="isStart ? 1074 : 1084"></app-help-selector>
                                </span>
                                <span class="alpaca-icon-required text-danger"></span>
                            </div>

                            <mat-form-field>
                                <input tabindex="-1" matInput readonly (click)="progressDatePicker.open()" [matDatepicker]="progressDatePicker" formControlName="progressDate"
                                    [placeholder]="isStart ? 'Start Date' : 'Progress Date'">
                                <mat-datepicker-toggle matIconSuffix [for]="progressDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #progressDatePicker></mat-datepicker>
                            </mat-form-field>

                            <div class="hub-validation-error-message" [innerHtml]="getValidationErrorMessages('progressDate')"></div>

                        </div>

                        <div class="progress-value-column text-right">
                            <div>
                                <span class="select-label text-info-800 pb-2">
                                    {{ isStart ? 'Start' : 'Progress' }} Value
                                    <app-help-selector [selector]="isStart ? 1076 : 1086"></app-help-selector>
                                </span>
                                <span class="alpaca-icon-required text-danger"></span>

                            </div>

                            <mat-form-field *ngIf="isScaleMeasure">
                                <input matInput type="number" class="form-control"
                                    [formControl]="progressForm.controls.progressValue"
                                    [value]="progressForm.controls.progressValue.value"
                                    [placeholder]="isStart ? 'Start Value' : 'Progress Value'"
                                    [min]="getScaleDefinitions()[0].scaleMeasureItem.value"
                                    [max]="getScaleDefinitions()[getScaleDefinitions().length - 1].scaleMeasureItem.value"
                                    step="1" />
                            </mat-form-field>

                            <mat-form-field *ngIf="!isScaleMeasure">
                                <input matInput type="number" class="form-control" formControlName="progressValue"
                                [placeholder]="isStart ? 'Start Value' : 'Progress Value'" />
                            </mat-form-field>

                            <div class="hub-validation-error-message" [innerHtml]="getValidationErrorMessages('progressValue')"></div>
                        </div>

                        <div class="unit-name-column">
                            <span class="select-label text-info-800 d-block">
                                Units<app-help-selector [selector]="1099"></app-help-selector>
                            </span>

                            <div class="form-control" disabled="disabled" style="padding-top: 13px; padding-bottom: 18px">
                                {{ intermediateResult.unit }}
                            </div>

                        </div>


                    </div>
                </div>

            </div>

            <div class="row" *ngIf="isScaleMeasure">
                <div class="col-md-12">
                    <div class="select-label text-info-800 mt-3 mb-2"> {{ isStart ? 'Start' : 'Progress'}} Value:</div>
                </div>
                <div class="scale-slider-panel">
                    <div class="scale-slider-line"></div>

                    <mat-radio-group [formControl]="progressForm.controls.progressValue"
                        [value]="progressForm.controls.progressValue.value"
                        style="display: flex; flex-direction: row; justify-content: space-between;">

                        <mat-radio-button [matTooltip]="scaleDefinition.description" matTooltipPosition="above"
                            matTooltipShowDelay="500" matTooltipHideDelay="200"
                            *ngFor="let scaleDefinition of getScaleDefinitions()" color="primary"
                            [value]="scaleDefinition.scaleMeasureItem.value"></mat-radio-button>
                    </mat-radio-group>

                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                        <div class="scale-label" *ngFor="let scaleDefinition of getScaleDefinitions(); index as i"
                            [ngClass]="{'first': i === 0, 'last': i === getScaleDefinitions().length - 1 }">
                            {{scaleDefinition.scaleMeasureItem.label}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block mt-2">
                        {{isStart ? 'Start': 'Progress'}} Description
                        <app-help-selector [selector]="isStart ? 1082 : 1183">
                        </app-help-selector>
                    </span>
                    <textarea rows="3" cols="5" maxlength="800" class="form-control record-description"
                        name="attributionDescription" formControlName="attributionDescription"
                        placeholder="Enter your {{isStart ? 'start' : 'progress'}} description here"></textarea>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block mt-2 pb-2">TNC's Role
                        <app-help-selector [selector]="1080"></app-help-selector>
                    </span>
                    <div class="hub-select-container">
                        <mat-form-field>
                            <mat-select placeholder="Select TNC's Role" disableOptionCentering
                                formControlName="attributionLevelId">
                                <mat-option *ngFor="let level of attributionLevels" [value]="level.attributionLevelId">
                                    {{level.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <div class="hub-modal-footer-text">
        <button class="cancel-button mr-1" (click)="cancel()">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="progressForm.invalid || isSaving" (click)="save()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>

    </div>

</div>
