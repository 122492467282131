import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RecordCollectionModel, RecordCollectionProjectModel, RecordCollectionTypeModel, RecordCollectionsSearchModel, RecordCollectionsSearchResultModel } from '../../../hub_schema/hubTypes';
import { HttpService } from './http.service';
import { HubLovService } from './hub-lov.service';

@Injectable()
export class RecordCollectionService {
    private httpService: HttpService = inject(HttpService);
    private hubLovService: HubLovService = inject(HubLovService);

    // #region Getters

    public getAllRecordCollections(): Observable<RecordCollectionModel[]> {
        const url = environment.endpoints.base + '/recordCollections';
        return this.httpService.get<RecordCollectionModel[]>(url);
    }

    public getRecordCollectionsByEmployee(employeeId: number): Observable<RecordCollectionModel[]> {
        const url = environment.endpoints.base + '/recordCollections/user/' + employeeId;
        return this.httpService.get<RecordCollectionModel[]>(url);
    }

    public searchRecordCollections(recordCollectionsSearchModel: RecordCollectionsSearchModel): Observable<RecordCollectionsSearchResultModel> {
        const url = environment.endpoints.base + '/recordCollections/search';
        return this.httpService.post<RecordCollectionsSearchResultModel>(url, recordCollectionsSearchModel);
    }

    public getRecordCollectionDetail(recordCollectionId: number): Observable<RecordCollectionModel> {
        const url = environment.endpoints.base + '/recordCollections/' + recordCollectionId;
        return this.httpService.get<RecordCollectionModel>(url);
    }

    // #endregion Getters

    public createRecordCollection(recordCollection: RecordCollectionModel): Observable<RecordCollectionModel> {
        const url = environment.endpoints.base + '/recordCollections';
        return this.httpService.post<RecordCollectionModel>(url, recordCollection);
    }

    public updateRecordCollection(recordCollection: RecordCollectionModel): Observable<RecordCollectionModel> {
        const url = environment.endpoints.base + '/recordCollections';
        return this.httpService.patch<RecordCollectionModel>(url, recordCollection);
    }

    public deleteRecordCollection(recordCollection: RecordCollectionModel): Observable<void> {
        const url = environment.endpoints.base + '/recordCollections/' + recordCollection.recordCollectionId;
        return this.httpService.delete(url);
    }

    public addProjectToRecordCollection(recordCollectionProject: RecordCollectionProjectModel): Observable<RecordCollectionProjectModel> {
        const url = environment.endpoints.base + '/recordCollections/recordCollectionProjects';
        return this.httpService.post<RecordCollectionProjectModel>(url, recordCollectionProject);
    }

    public deleteProjectFromCollection(recordCollectionProjectId: number): Observable<void> {
        const url = environment.endpoints.base + '/recordCollections/recordCollectionProjects/' + recordCollectionProjectId;
        return this.httpService.delete(url);
    }
}
