<div class="bar-chart" [ngClass]="{'sidebar-expanded': isSidebarExpanded}">
    <div class="bar-chart-data">
        <div class="series-row" [style.height.%]="getTncContributionHeight(subTheme.tncPrincipalPercent * 100)" >
            <div class="series-label">
                <div class="label-text">
                    {{ getTncContributionText(subTheme.tncPrincipalPercent * 100) }}
                </div>
            </div>
            <div class="bar principal {{'goal-background-' + goal.goal2030Id}}">
            </div>
        </div>
        <div class="series-row" [style.height.%]="getTncContributionHeight(subTheme.tncDirectPercent * 100)">
            <div class="series-label">
                <div class="label-text">
                    {{ getTncContributionText(subTheme.tncDirectPercent * 100) }}
                </div>
            </div>
            <div class="bar direct {{'goal-background-' + goal.goal2030Id}}">

            </div>
        </div>
        <div class="series-row" [style.height.%]="getTncContributionHeight(subTheme.tncIndirectPercent * 100)">
            <div class="series-label">
                <div class="label-text">
                    {{ getTncContributionText(subTheme.tncIndirectPercent * 100) }}
                </div>
            </div>
            <div class="bar indirect {{'goal-background-' + goal.goal2030Id}}">

            </div>
        </div>
    </div>
    <div class="bar-chart-legend">
        <div class="legend-content-panel">
            <div class="legend-row">
                <div class="circle principal {{'goal-background-' + goal.goal2030Id}}"></div>
                <div class="legend-label">
                    Principal
                </div>
            </div>
            <div class="legend-row">
                <div class="circle direct {{'goal-background-' + goal.goal2030Id}}"></div>
                <div class="legend-label">
                    Direct
                </div>
            </div>
            <div class="legend-row">
                <div class="circle indirect {{'goal-background-' + goal.goal2030Id}}"></div>
                <div class="legend-label">
                    Indirect
                </div>
            </div>

        </div>
    </div>
</div>
